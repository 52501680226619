<template>
  <canvas :height="height" :width="width" :id="this.id"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["id", "type", "labels", "dataChart", "height", "description"],
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    dados() {
      this.montaChart();
    }
  },
  methods: {
    unmountChart() {
      this.chart.destroy();
    },
    montaChart() {
      var tela = document.getElementById(this.id).getContext("2d");
      this.chart = new Chart(tela, {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.description,
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(75, 192, 192)',
                'rgb(255, 205, 86)',
                'rgb(201, 203, 207)',
              ],
              data: this.dataChart,
              // fontColor: "rgba(255,255,255,1)",
              // color: "rgba(255,255,255,1)"
            },
          ]
        },
        options: {
          legend: {
            display: true,
            position: 'right' // Coloque a legenda na parte inferior
          },
          responsive: true,
        },
      });
    }
  },
  mounted() {
    this.montaChart();
  }
};
</script>
